<template>
  <b-card class="card-custom card-stretch gutter-b" no-body>
    <b-card-header>
      <b-card-title class="font-weight-bolder">
        Clienți
      </b-card-title>

      <div class="card-toolbar">
        <b-input-group size="sm">
          <b-input-group-prepend v-bind:is-text="true">
            <i class="fas fa-search" />
          </b-input-group-prepend>

          <b-form-input class="form-control" size="sm" placeholder="nume, telefon" v-model="search" />
        </b-input-group>
      </div>
    </b-card-header>

    <b-card-body>
      <Table
        v-bind:loading="loading"
        v-bind:customers="customers"
        v-bind:sort.sync="sort"
        v-on:suspend="suspend"
        v-on:reinstate="reinstate"
      />
    </b-card-body>

    <b-card-footer>
      <Pagination
        v-model="page"
        v-bind:page-size="pageSize"
        v-bind:page-options="pageOptions"
        v-bind:total="total"
        v-on:page-size-change="pageSizeChanged"
      />
    </b-card-footer>

    <SuspendDialog ref="suspendDialog" />
    <ReinstateDialog ref="reinstateDialog" />
  </b-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

import api from "@/core/services/api";
import notify from "@/core/services/notifications";
import { mapOrder } from "@/core/models/order";

import Pagination from "@/view/content/widgets/Pagination.vue";
import SuspendDialog   from "@/view/content/dialogs/customers/Suspend.vue";
import ReinstateDialog from "@/view/content/dialogs/customers/Reinstate.vue";
import ListMixin from "@/view/mixins/List.js";

import Table from "./customers/list/Table";

function mapCustomer(customer) {
  const created   = moment(customer.created);
  const enabled   = customer.enabled ? moment(customer.enabled) : null;
  const lastOrder = customer.lastOrder ? mapOrder(customer.lastOrder) : null;

  if (lastOrder) {
    lastOrder.customer = {
      name: customer.name,
      phone: customer.phone,
    };
  }

  let status;
  if (!customer.enabled) {
    status = "disabled";
  } else if (customer.suspended) {
    status = "suspended";
  } else if (customer.paired) {
    status = "paired";
  } else if (customer.confirmed) {
    status = "confirmed";
  } else {
    status = "enabled";
  }

  return {
    id: customer.id,
    name: customer.name,
    phone: customer.phone,

    status,
    suspended: customer.suspended,
    lastOrder,

    created: Object.freeze(created),
    enabled: Object.freeze(enabled),
  };
}

export default {
  name: "Customers",

  components: {
    Table,
    Pagination,

    SuspendDialog,
    ReinstateDialog,
  },

  mixins: [
    ListMixin
  ],

  data() {
    return {
      customers: [],

      debounceDelay: moment.duration(250, "ms"),
      defaultSort: "name",
    };
  },

  methods: {
    ...mapActions(["overridePageLayoutConfig"]),

    async load() {
      this.loading = true;

      const response = await api.customers.list(this.offset, this.limit, this.search, this.sort);

      this.loading   = false;
      this.total     = response.range.total;
      this.customers = response.results.map(mapCustomer);
    },

    async refresh(customer) {
      const response = await api.customers.load(customer.id);
      const mapped   = mapCustomer(response);

      const index = this.customers.findIndex(c => c.id === customer.id);
      if (index !== -1) {
        this.$set(this.customers, index, mapped);
      }
    },

    async suspend(customer) {
      const suspended = await this.$refs.suspendDialog.show(customer);

      if (suspended) {
        const { duration, reason } = suspended;

        await api.customers.suspend(customer.id, duration.toISOString(), reason);

        customer.suspended = {
          at:    moment(),
          until: moment().add(duration),
          reason,
        };

        notify.info(`Clientul <b>${customer.name}</b> a fost blocat pentru <b>${duration.humanize()}</b>.`);
      }
    },

    async reinstate(customer) {
      const reinstated = await this.$refs.reinstateDialog.show(customer);

      if (reinstated) {
        await api.customers.reinstate(customer.id);

        customer.suspended = null;

        notify.info(`Clientul <b>${customer.name}</b> a fost deblocat.`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-header {
    border-bottom: none;
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .card-footer {
    .pagination {
      margin-bottom: 0;
    }

    .dropdown {
      margin-bottom: 0;
    }
  }
}
</style>
