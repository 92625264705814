<template>
  <Progress
    v-bind:variant="progressColor"
    v-bind:value="progressValue"
    v-bind:max="progressMax"
  >
    <template v-slot:left>
      <div class="text-muted mr-2 font-size-sm font-weight-bold">
        <span class="text-danger" v-b-tooltip v-bind:title="suspendedAt | timestamp">
          Blocat pentru: {{ suspendedFor | duration }}
        </span>
        |
        <span class="text-dark">
          Trecut: {{ elapsed | duration }}
        </span>
        |
        <span class="text-warning" v-b-tooltip v-bind:title="suspendedUntil | timestamp">
          Rămas: {{ remaining | duration }}
        </span>
      </div>
    </template>

    <template v-if="suspension.reason" v-slot:bottom-left>
      <div class="text-info mr-2 font-size-sm font-weight-bold">
        Motiv: {{ suspension.reason }}
      </div>
    </template>
  </Progress>
</template>

<script>
import moment from "moment";

import Progress from "@/view/content/widgets/Progress.vue";

export default {
  components: {
    Progress,
  },

  filters: {
    duration:  duration  => moment.duration(duration).humanize(),
    timestamp: timestamp => moment(timestamp).format('D MMMM Y [la] HH:mm:ss'),
  },

  props: {
    suspension: {
      type: Object,
      required: true,
    },
  },

  data() {
    const suspendedAt    = moment(this.suspension.at);
    const suspendedUntil = moment(this.suspension.until);
    const suspendedFor   = moment.duration(suspendedUntil.clone().diff(suspendedAt));

    const elapsed = moment.duration(moment().diff(suspendedAt));

    let remaining = moment.duration(suspendedUntil.diff(moment()));
    if (remaining.asMilliseconds() < 0)
      remaining = moment.duration(0);

    return {
      suspendedAt:   Object.freeze(suspendedAt),
      suspendedUntil: Object.freeze(suspendedUntil),
      suspendedFor:   Object.freeze(suspendedFor),

      elapsed:   Object.freeze(elapsed),
      remaining: Object.freeze(remaining),

      progressValue: elapsed.asSeconds(),
      progressMax:   suspendedFor.asSeconds(),
    };
  },

  computed: {
    progressColor() {
      if (this.remaining.asMinutes() <= 0) {
        return "success";
      } else if (this.remaining.asMinutes() < 10) {
        return "warning";
      } else {
        return "danger";
      }
    },
  },

  methods: {
    cancelSuspension() {
      this.$emit("cancel");
    },
  },
};
</script>
