<template>
  <b-modal
    centered
    ok-title="Blochează"
    ok-variant="danger"
    cancel-title="Renunță"
    cancel-variant="secondary"
    v-model="visible"
    v-bind:title="title"
    v-bind:ok-disabled="!selected || !reason || reason.length === 0"
    v-on:hide="modalHidden"
  >
    <b-form-group
      label="Durată blocare:"
      label-class="h5 text-dark"
    >
      <b-form-radio-group
        buttons
        button-variant="outline-danger"
        v-model="selected"
        v-bind:options="options"
      />
    </b-form-group>

    <b-form-group
      label="Motiv blocare:"
      label-class="h6"
    >
      <b-form-input v-model="reason" placeholder="introduceți un motiv" />
    </b-form-group>
  </b-modal>
</template>

<script>
import moment from "moment";

const options = [
  moment.duration(1,  "week"),
  moment.duration(1,  "month"),
  moment.duration(3,  "months"),
  moment.duration(6,  "months"),
  moment.duration(1,  "year"),
  moment.duration(10, "years")
];

function mapOption(duration) {
  return {
    text:  duration.humanize(),
    value: { duration },
  }
}

export default {
  data() {
    return {
      options: options.map(mapOption),

      customer: null,

      selected: null,
      reason:   null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  computed: {
    title() {
      if (this.customer) {
          return `Blochează client ${this.customer.name}`;
      } else {
          return "";
      }
    },
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    show(customer) {
      this.customer = customer;

      this.selected = null;
      this.reason   = null;

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.customer = null;

      this.selected = null;
      this.reason   = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    modalHidden(event) {
      let result = false;

      if (event.trigger === 'ok') {
        result = {
          duration: this.selected.duration,
          reason:   this.reason,
        };
      }

      this.resolve(result);
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-body {
        .form-group {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>
