<template>
  <div>
    <!-- reinstate -->
    <b-button
      class="btn btn-icon btn-light btn-hover-success btn-sm"
      v-if="isSuspended"
      v-b-tooltip.hover="'Deblochează'"
      v-on:click="reinstate"
    >
      <i class="fas fa-lock-open text-success" />
    </b-button>

    <!-- suspend -->
    <b-button
      class="btn btn-icon btn-light btn-hover-danger btn-sm"
      v-else
      v-b-tooltip.hover="'Blochează'"
      v-on:click="suspend"
    >
      <i class="fas fa-lock text-danger" />
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    isSuspended() {
      return this.customer.suspended;
    },
  },

  methods: {
    suspend() {
      this.$emit("suspend", this.customer);
    },

    reinstate() {
      this.$emit("reinstate", this.customer);
    },
  },
};
</script>
