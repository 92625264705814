<template>
  <span class="label" v-bind:class="classes">
    <slot />
  </span>
</template>

<script>
const kinds = [ "dot", "inline", "pill", "rounded", "square" ];

const sizes = [ "sm", "md", "lg", "xl" ];

const variants = [
  "primary", "secondary", "success", "danger", "warning", "info",
  "light", "dark",
  "light-primary", "light-secondary", "light-success", "light-danger", "light-warning", "light-info"
];

const weights = [ "lighter", "light", "normal", "bold", "bolder", "boldest" ];

export default {
  props: {
    kind: {
      type: String,
      default: null,
      validator: (kind) => kinds.includes(kind),
    },

    outline: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: null,
      validator: (size) => sizes.includes(size),
    },

    variant: {
      type: String,
      default: null,
      validator: (variant) => variants.includes(variant),
    },

    weight: {
      type: String,
      default: null,
      validator: (weight) => weights.includes(weight),
    },
  },

  data() {
    return {};
  },

  computed: {
    classes() {
      let classes = [];

      if (this.kind) {
        classes.push(`label-${this.kind}`);

        if (this.kind === "pill") {
          classes.push("label-inline");
        }
      }

      if (this.size) {
        classes.push(`label-${this.size}`);
      }

      if (this.variant) {
        if (this.outline && !this.variant.startsWith("light")) {
          classes.push(`label-outline-${this.variant}`);
        } else {
          classes.push(`label-${this.variant}`);
        }
      }

      if (this.weight) {
        classes.push(`font-weight-${this.weight}`);
      }

      return classes;
    },
  },
};
</script>
